import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const DonatePage = () => (
  <Layout>
    <SEO title="Donate" />
  </Layout>
)

export default DonatePage
